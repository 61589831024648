import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const columns = [
  { id: "employeeId", label: "Employee ID", minWidth: 170 },
  { id: "salesDate", label: "Sales Date", minWidth: 170 },
  { id: "item", label: "Item", minWidth: 170 },
  { id: "quantity", label: "Quantity", minWidth: 170 },
];

function createData(employeeId, salesDate, item, quantity) {
  return {
    employeeId,
    salesDate,
    item,
    quantity,
  };
}

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Fetch data from your backend API here
    /*
     const response = await fetch(
        "https://backattendance.tfdatamaster.com/api/dashboard/allusers",
        {
          method: "GET",
          headers: {
            "auth-token": `${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      ); // Replace with your backend API endpoint
    */
    const token = localStorage.getItem("accessToken");

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://backattendance.tfdatamaster.com/api/sales/getsales",
          {
            method: "GET",
            headers: {
              "auth-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        // Assuming your backend returns an array of objects with the required properties
        const formattedData = data.map((item) => {
          // Parse the date string and format it as dd/mm/yy
          const date = new Date(item.date);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${
            date.getFullYear() % 100
          }`;

          return createData(
            item.employeeid,
            formattedDate,
            item.selectedItem,
            item.quantity
          );
        });

        setTableData(formattedData);
        console.log("Fetch data", formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredRows = tableData.filter(
    (row) =>
      row.employeeId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.salesDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.item.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.quantity.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <InputBase
        className="search-leave"
        placeholder="Search by Employee ID, Sales Date, Item, or Quantity"
        value={searchTerm}
        onChange={handleSearchChange}
        startAdornment={<SearchIcon />}
        sx={{ marginBottom: "20px" }}
      />

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.employeeId}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align || "left"}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
